* {
  margin: 0;
  padding: 0;
}

a.navlink:hover {
  background-color: rgb(234 232 232) !important;
  transition: all 300ms;
}

a.navlink.disabled {
  pointer-events: none !important;
}
